// TODO: Move to own file
import { createTheme } from '@mui/material';
import { teal } from '@mui/material/colors';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: teal[800]
    }
  },
  typography: {
    title: {
      fontSize: '2rem',
      fontWeight: 600,
      fontFamily: ['Playfair Display', 'serif'].join(',')
    }
  }
});
