import { LaanVaelger } from './laan-vaelger';
import { LaanDetaljer } from './laan-detaljer';
import { AnnuitetsTabel } from './annuitets-tabel';
import { ChangeEvent, useState } from 'react';
import { beregnLaan, Laan } from '../../domain/laan-beregner';
import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { Slider } from '../../components/slider/slider';
import { LoanGroup, useGroupedLaanQuery } from './data/use-laan-query';
import { BreakEvenChart, OmlaegningCharts } from './omlaegning-charts';
import BeregnerInputContainer from '../../components/ui/beregner-input-container';
import { ResponsiveSideBySide } from '../../components/ui/responsive-side-by-side';
import DisclaimerPopover from '../disclaimer/disclaimer-popover';

function getMaxRestLoebetid(laan: Laan) {
  return (laan.udloebsAar || 2053) - new Date().getFullYear();
}

export const LaanOmlaegningView = ({
  laanIndfrielse,
  laanOptagelse
}: {
  laanIndfrielse: LoanGroup[];
  laanOptagelse: LoanGroup[];
}) => {
  // Nuværende lån
  const [boligPris, setBoligPris] = useState(2500000);
  const [restgaeld, setRestgaeld] = useState(2000000);
  const [nuvaerendeLaan, setNuvaerendeLaan] = useState(
    laanIndfrielse.flatMap((group) => group.loans)[0]
  );
  const [restLoebetid, setRestLoebetid] = useState(getMaxRestLoebetid(nuvaerendeLaan));
  const maxRestLoebetid = getMaxRestLoebetid(nuvaerendeLaan);

  // Nyt lån:
  const [nytLaan, setNytLaan] = useState(laanOptagelse.flatMap((group) => group.loans)[0]);
  const [nyRestLoebetid, setNyRestLoebetid] = useState(30);

  // - Huset vurdering (ved oprindelig lån optagelse)
  // - Restgæld
  // - Restløbetid
  // - "Lån vælger" (obligationsserie vælger)

  // Nyt lån:
  // - Lån vælger
  // - Ny restløbetid

  const handleChange =
    (setter: (value: number) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      setter(parseFloat(event.target.value));
    };

  // Nuværende lån
  const nuvaerendeLaanBeregning = beregnLaan(nuvaerendeLaan, restLoebetid * 4, restgaeld, [0, 80]);

  // Nyt lån
  const indfrielse = (restgaeld * Math.min(100, nuvaerendeLaan.kurs)) / 100;
  const nyHovedstol = (indfrielse / nytLaan.kurs) * 100;
  const interval = (nyHovedstol / boligPris) * 100;
  const nyLaanBeregning = beregnLaan(nytLaan, nyRestLoebetid * 4, nyHovedstol, [0, interval]);

  // Sammenligning
  const maxLoebetid = Math.max(nyRestLoebetid, restLoebetid);
  const restgaeldDifference = restgaeld - nyLaanBeregning.hovedstol;

  const mdlYdelseEfterSkatDifference =
    (nyLaanBeregning.ydelseEfterSkat[0] - nuvaerendeLaanBeregning.ydelseEfterSkat[0]) / 3;
  const mdlAfdragDifference = (nyLaanBeregning.afdrag[0] - nuvaerendeLaanBeregning.afdrag[0]) / 3;

  return (
    <Stack flex="auto">
      <BeregnerInputContainer>
        <TextField
          label={'Restgæld'}
          type="number"
          variant="outlined"
          inputProps={{ step: 50000 }}
          value={restgaeld}
          onChange={handleChange(setRestgaeld)}
          sx={{ backgroundColor: '#FAFAFA', maxWidth: '250px' }}
        />
        <TextField
          label={'Bolig pris'}
          type="number"
          variant="outlined"
          inputProps={{ step: 50000 }}
          value={boligPris}
          onChange={handleChange(setBoligPris)}
          sx={{ backgroundColor: '#FAFAFA', maxWidth: '250px' }}
        />
        <ResponsiveSideBySide
          firstTitle="Eksisterende lån"
          firstChild={
            <>
              <LaanVaelger
                label="Eksisterende lån"
                laanOptions={laanIndfrielse}
                onSelectLoan={(laan) => {
                  const atMax = maxRestLoebetid == restLoebetid;
                  const newMaxRestLoebetid = getMaxRestLoebetid(laan);
                  setNuvaerendeLaan(laan);
                  if (atMax) {
                    setRestLoebetid(newMaxRestLoebetid);
                  } else {
                    setRestLoebetid(Math.min(newMaxRestLoebetid, restLoebetid));
                  }
                }}
              />
              <Slider
                value={restLoebetid}
                min={0}
                max={maxRestLoebetid}
                label="Restløbetid"
                onChange={setRestLoebetid}
              />
            </>
          }
          secondTitle="Nyt lån"
          secondChild={
            <>
              <LaanVaelger label="Nyt lån" laanOptions={laanOptagelse} onSelectLoan={setNytLaan} />
              <Slider
                value={nyRestLoebetid}
                min={0}
                max={30}
                label="Restløbetid"
                onChange={setNyRestLoebetid}
              />
            </>
          }
        />
        <DisclaimerPopover />
      </BeregnerInputContainer>
      <Stack spacing={2} p={2} sx={{ overflowX: 'scroll' }}>
        <Typography variant="h6" gutterBottom>
          Restgæld
        </Typography>
        <Box height={300}>
          <OmlaegningCharts
            nyLaanBeregning={nyLaanBeregning}
            nuvaerendeLaanBeregning={nuvaerendeLaanBeregning}
            laanNuvaerende={nuvaerendeLaan}
            maxLoebetid={maxLoebetid}
          />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#202020' }}>
          Over tid, når du betaler af på dit lån, falder din restgæld. I begyndelsen vil
          størstedelen af dine betalinger gå til renter, men efterhånden som du betaler mere af, går
          en større del af dine betalinger til selve lånet og din restgæld falder hurtigere. Ved
          omlægning til højere rente vil restgælden være mindre i starten, tilgengæld betales, der
          typisk langsommere af.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Breakeven
        </Typography>
        <Box height={300}>
          <BreakEvenChart
            nyLaanBeregning={nyLaanBeregning}
            nuvaerendeLaanBeregning={nuvaerendeLaanBeregning}
            maxLoebetid={maxLoebetid}
          />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#202020' }}>
          Breakeven-punktet er det tidspunkt, hvor linjen krydser 0 på y-aksen. Dette er det
          tidspunkt, hvor de akkumulerede ydelser (dvs. det samlede beløb, du har betalt indtil
          videre) og restgælden (det beløb, du stadig skylder) er de samme for begge lån. Når
          breakeven balancen er negativ ville du være bedre stillet med det nye lån; når den er
          positiv ville du være bedre stillet med det nuværende lån.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Hovedtal
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Restgælder {restgaeldDifference > 0 ? 'falder' : 'stiger'}
              </TableCell>
              <TableCell align="right">
                {Math.round(Math.abs(restgaeldDifference)).toLocaleString()} kr.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Månedlig ydelse efter skat {mdlYdelseEfterSkatDifference > 0 ? 'stiger' : 'falder'}
              </TableCell>
              <TableCell align="right">
                {Math.round(Math.abs(mdlYdelseEfterSkatDifference)).toLocaleString()} kr.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Månedlig afdrag {mdlAfdragDifference > 0 ? 'stiger' : 'falder'}
              </TableCell>
              <TableCell align="right">
                {Math.round(mdlAfdragDifference).toLocaleString()} kr.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography variant="h6" gutterBottom>
          Nyt lån detaljer
        </Typography>
        <LaanDetaljer laanBeregning={nyLaanBeregning} />

        {/*<OmlaegningsTabel differenceYdelse={merYdelse} differenceRestgaeld={restGaeldDifference} />*/}
        <Typography variant="h6" gutterBottom>
          Nyt lån annuitetstabel
        </Typography>
        <AnnuitetsTabel laanBeregning={nyLaanBeregning} />
      </Stack>
    </Stack>
  );
};

export const LaanOmlaegning = () => {
  const { data: laanIndfrielse = [], isLoading } = useGroupedLaanQuery(
    'indfrielse',
    //'https://72ylpd13k2.execute-api.eu-north-1.amazonaws.com/v1/api/kurser'
    '/v1/api/kurser'
  );

  const { data: laanOptagelse = [], isLoading: isLoadingOptagelse } = useGroupedLaanQuery(
    'optagelse',
    //'https://72ylpd13k2.execute-api.eu-north-1.amazonaws.com/v1/api/kurser'
    '/v1/api/kurser'
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {(isLoading || isLoadingOptagelse) && <CircularProgress />}
      {laanIndfrielse.length > 0 && laanOptagelse.length > 0 && (
        <LaanOmlaegningView laanIndfrielse={laanIndfrielse} laanOptagelse={laanOptagelse} />
      )}
    </Box>
  );
};
