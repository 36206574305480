import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { getUser } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { GetUserQuery, UserLoan } from '@app/API';
import { PermIdentityOutlined, SavingsOutlined } from '@mui/icons-material';

export const Profile = () => {
  const { user: authUser } = useAuthenticator((context) => [context.user]);
  const [profile, setProfile] = useState<GetUserQuery>({ getUser: null });

  const client = generateClient();
  useEffect(() => {
    fetchUser();
  }, [authUser]);

  async function fetchUser() {
    try {
      console.log('authUser', authUser);
      const user = await client.graphql({ query: getUser, variables: { owner: authUser.userId } });
      console.log('user', user);
      setProfile(user.data);
    } catch (err) {
      console.log('error fetching user');
    }
  }

  return (
    <Box width={'50%'}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <PermIdentityOutlined color="primary"></PermIdentityOutlined>
            </TableCell>
            <TableCell align="right">{authUser?.signInDetails?.loginId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <SavingsOutlined color="primary"></SavingsOutlined>
            </TableCell>
            <TableCell align="right">
              <UserLoanTable userLoan={profile.getUser?.loan} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

const UserLoanTable = ({ userLoan }: { userLoan?: UserLoan | null }) => {
  return <>{userLoan?.principal}</>;
};
