import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { LaanBeregning } from '@app/domain/laan-beregner';

export interface LaanDetaljerProps {
  laanBeregning: Pick<
    LaanBeregning,
    | 'hovedstol'
    | 'tkYdelse'
    | 'tkYdelseEfterSkat'
    | 'tkRenteOgBidragTotal'
    | 'tkAfdrag'
    | 'tkSamletBeloeb'
    | 'bidragsSats'
  >;
}

export const LaanDetaljer = ({ laanBeregning }: LaanDetaljerProps) => {
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Hovedstol
            </TableCell>
            <TableCell align="right">
              {Math.round(laanBeregning.hovedstol).toLocaleString()} kr.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Ydelse pr. måned før skat
            </TableCell>
            <TableCell align="right">
              {Math.round(laanBeregning.tkYdelse).toLocaleString()} kr.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Ydelse pr. måned efter skat
            </TableCell>
            <TableCell align="right">
              {Math.round(laanBeregning.tkYdelseEfterSkat).toLocaleString()} kr.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Første måneds afdrag
            </TableCell>
            <TableCell align="right">
              {Math.round(laanBeregning.tkAfdrag).toLocaleString()} kr.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Rente og bidrag i lånets løbetid
            </TableCell>
            <TableCell align="right">
              {Math.round(laanBeregning.tkRenteOgBidragTotal).toLocaleString()} kr.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Samlet beløb, der skal betales i lånets løbetid
            </TableCell>
            <TableCell align="right">
              {Math.round(laanBeregning.tkSamletBeloeb).toLocaleString()} kr.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Bidragssats pr. år
            </TableCell>
            <TableCell align="right">
              {Math.round(laanBeregning.bidragsSats * 10000) / 100} %
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
