import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LaanBeregning } from '@app/domain/laan-beregner';

export interface AnnuitetsTabelProps {
  laanBeregning: Pick<LaanBeregning, 'afdrag' | 'ydelse' | 'restgaeld' | 'ydelseEfterSkat'>;
}

export const AnnuitetsTabel = ({ laanBeregning }: AnnuitetsTabelProps) => {
  return (
    <TableContainer
      sx={{
        overflowX: 'auto',
        maxWidth: 'min(1136px, calc(100vw - 64px))',
        width: '90vw',
        display: 'flex',
        alignItems: 'center'
      }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Kvartal</TableCell>
            <TableCell align="right">Afdrag</TableCell>
            <TableCell align="right">Restgæld</TableCell>
            <TableCell align="right">Ydelse</TableCell>
            <TableCell align="right">Ydelse efter skat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {laanBeregning.afdrag.map((e, i) => (
            <TableRow key={i.toString()}>
              <TableCell component="th" scope="row">
                {i + 1}.
              </TableCell>
              <TableCell align="right">
                {Math.round(laanBeregning.afdrag[i]).toLocaleString()} kr.
              </TableCell>
              <TableCell align="right">
                {Math.round(laanBeregning.restgaeld[i]).toLocaleString()} kr.
              </TableCell>
              <TableCell align="right">
                {Math.round(laanBeregning.ydelse[i]).toLocaleString()} kr.
              </TableCell>
              <TableCell align="right">
                {Math.round(laanBeregning.ydelseEfterSkat[i]).toLocaleString()} kr.
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
