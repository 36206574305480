import React, { useState } from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';

const DisclaimerPopover = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const popoverId = popoverOpen ? 'disclaimer-popover' : undefined;

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button variant="text" color="primary" onClick={handlePopoverOpen}>
        FORBEHOLD
      </Button>
      <Popover
        id={popoverId}
        open={popoverOpen}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          '.MuiPopover-paper': {
            maxWidth: 400
          },
          '.MuiTypography-root': {
            padding: 2,
            borderRadius: 'inherit',
            backgroundColor: 'primary.main',
            color: 'primary.contrastText'
          }
        }}>
        <Typography sx={{ p: 2 }}>
          Vigtig disclaimer: Beregningerne på denne hjemmeside er udelukkende vejledende, og
          Realkred.it fraskriver sig ethvert ansvar for eventuelle fejl eller mangler i
          oplysningerne og beregningerne. Brugere kan ikke rette erstatningskrav mod Realkred.it som
          følge af tab opstået ved brug af oplysningerne og/eller beregningerne. Bemærk, at
          Realkred.it ikke er en finansiel virksomhed og tilbyder derfor ikke lån baseret på de
          angivne forudsætninger. Informationen på denne side er udarbejdet af Realkred.it som
          generel orientering. Oplysningerne er baseret på offentligt tilgængelige kilder, og
          Realkred.it påtager sig intet ansvar for nøjagtigheden, fuldstændigheden eller
          pålideligheden af informationen. Oplysningerne udgør ikke en opfordring til at handle
          værdipapirer, og Realkred.it påtager sig intet ansvar for dispositioner foretaget på
          baggrund af informationen. Vær opmærksom på, at Realkred.it ikke har økonomiske interesser
          i de nævnte værdipapirer eller finansielle produkter. Ved at anvende denne hjemmeside
          accepterer du, at Realkred.it ikke kan drages til ansvar for eventuelle tab eller skader
          som følge af brugen af beregningerne, oplysningerne eller rådgivningen på hjemmesiden. Vi
          anbefaler dig altid at søge individuel rådgivning fra en kvalificeret finansiel ekspert,
          før du træffer økonomiske beslutninger. Bemærk, at lovgivning og regler for realkreditlån
          kan variere, og det er dit ansvar at handle i overensstemmelse med gældende love og regler
          i dit område. Vi bestræber os på at sikre nøjagtigheden af oplysningerne og beregningerne
          på Realkred.it, men vi kan ikke garantere fuldstændig nøjagtighed. Derfor opfordrer vi dig
          til at verificere resultaterne og søge professionel rådgivning, hvis du er i tvivl. Ved at
          bruge denne hjemmeside accepterer du disse vilkår og betingelser for ansvarsfraskrivelse
          og erklærer dig indforstået med at overholde dem.
        </Typography>
      </Popover>
    </Box>
  );
};

export default DisclaimerPopover;
