import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Typography } from '@mui/material';
import { Authenticator, Theme, ThemeProvider, useTheme } from '@aws-amplify/ui-react';
import { AuthUser } from 'aws-amplify/auth';
import { SignOut } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator';
import { teal } from '@mui/material/colors';

export interface LoginDialogProps {
  open: boolean;
  onClose: () => void;
}

export function LoginDialog(props: LoginDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} sx={{ borderRadius: 0 }}>
      <DialogContent
        sx={{
          backgroundColor: 'whitesmoke',
          borderRadius: 0,
          borderColor: 'lightgray',
          borderStyle: 'solid',
          borderWidth: '1px',
          p: 0
        }}>
        <AuthStyle>
          {() => {
            handleClose();
            return <Typography>Logged in</Typography>;
          }}
        </AuthStyle>
      </DialogContent>
    </Dialog>
  );
}
export function AuthStyle({
  children
}: {
  children: React.ReactNode | ((props: { signOut?: SignOut; user?: AuthUser }) => JSX.Element);
}) {
  const { tokens } = useTheme();
  const theme: Theme = {
    name: 'Auth Example Theme',
    tokens: {
      fonts: {
        default: {
          variable: { value: 'Roboto, Helvetica, Arial, sans-serif' },
          static: { value: 'Roboto, Helvetica, Arial, sans-serif' }
        }
      },
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay['10']}`,
            borderWidth: '0'
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`
          }
        },
        button: {
          primary: {
            _hover: { backgroundColor: teal[800] },
            backgroundColor: tokens.colors.neutral['100']
          },
          link: {
            color: teal[800]
          }
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${teal[800]}`
          }
        },
        tabs: {
          item: {
            color: tokens.colors.neutral['80'],
            _active: {
              borderColor: tokens.colors.neutral['100'],
              color: teal[800]
            },
            _hover: {
              color: teal[800]
            }
          }
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Authenticator socialProviders={['google']}>{children}</Authenticator>
    </ThemeProvider>
  );
}
