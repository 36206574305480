export class BisectionMethod {
  public static findRoot(
    f: (x: number) => number,
    a: number,
    b: number,
    tol = 0.00001,
    maxIter = 1000
  ): number | null {
    if (f(a) * f(b) >= 0) {
      console.log('You have not assumed right a and b\n');
      return null;
    }

    let c = a;
    let iteration = 0;

    while (b - a >= tol) {
      // Find middle point
      c = (a + b) / 2;

      // Check if middle point is root
      if (f(c) == 0.0) {
        break;
      }

      // Decide the side to repeat the steps
      else if (f(c) * f(a) < 0) {
        b = c;
      } else {
        a = c;
      }

      iteration++;
      if (iteration > maxIter) {
        console.log('Exceeded maximum iterations');
        return null;
      }
    }

    return c;
  }
}
