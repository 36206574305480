import React, { useState } from 'react';
import './App.css';
import { Box, Stack, Tab, Tabs, ThemeProvider, Typography } from '@mui/material';
import { LaanOmlaegning } from './features/laan-beregner/laan-omlaegning';
import { StandardLaan } from './features/laan-beregner/standard-laan';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToLagsLaan } from './features/laan-beregner/to-lags-laan';
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import { RuntimeConfig, RuntimeConfigContext } from './domain/core/use-runtime-config';
import { Authenticator } from '@aws-amplify/ui-react';
import AccountMenu from './features/account/account-menu';
import { LoginDialog } from './features/login/login-dialog';
import { defaultTheme } from './themes';
import { Profile } from './features/account/profile';

// Create a client
const queryClient = new QueryClient();

declare global {
  interface Window {
    API_URL: string;
  }
}

const runtimeConfig: RuntimeConfig = {
  apiUrl: window.API_URL
};

function App() {
  const [loginDialogOpen, setLoginDialogOpen] = useState<boolean>(false);

  return (
    <Authenticator.Provider>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={defaultTheme}>
            <RuntimeConfigContext.Provider value={runtimeConfig}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box maxWidth="1200px" flex="auto">
                  <Box sx={{ px: 2, py: 1, borderBottom: 1, borderColor: 'divider' }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="title">Realkred.it</Typography>
                      <Stack direction="row" spacing={0.5}>
                        <AccountMenu openLogin={() => setLoginDialogOpen(true)} />
                      </Stack>
                    </Stack>
                  </Box>
                  <Box sx={{ px: 2, borderBottom: 1, borderColor: 'divider' }}>
                    <AppNav />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <AppRoutes />
                  </Box>
                </Box>
              </Box>

              {/* Login dialog */}
              <LoginDialog
                open={loginDialogOpen}
                onClose={() => {
                  setLoginDialogOpen(false);
                }}
              />
            </RuntimeConfigContext.Provider>
          </ThemeProvider>
        </QueryClientProvider>
      </Router>
    </Authenticator.Provider>
  );
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/standard-laan" element={<StandardLaan />} />
      <Route path="/to-lags-laan" element={<ToLagsLaan />} />
      <Route path="/profil" element={<Profile />} />
      <Route
        path="/login"
        element={
          <Authenticator>
            {({ signOut, user }) => (user ? <ToLagsLaan /> : <Navigate replace to="/" />)}
          </Authenticator>
        }
      />
      <Route path="/" element={<LaanOmlaegning />} />
    </Routes>
  );
};

const AppNav = () => {
  const location = useLocation();
  const value = ['/', '/standard-laan', '/to-lags-laan'].indexOf(location.pathname);

  return (
    <Tabs value={value} aria-label="basic tabs example">
      <Tab label="Omlægning" to="/" component={Link} />
      <Tab label="Standard lån" to="/standard-laan" component={Link} />
      <Tab label="To-lags lån" to="/to-lags-laan" component={Link} />
    </Tabs>
  );
};

export default App;
