import { Box, Grid } from '@mui/material';
import { ReactNode } from 'react';

export const ResponsiveSideBySide = ({
  firstChild,
  firstTitle,
  secondChild,
  secondTitle
}: {
  firstChild: ReactNode;
  firstTitle?: string;
  secondChild: ReactNode;
  secondTitle?: string;
}) => (
  <Grid container rowSpacing={1}>
    <Grid item xs={12} md={6} pl={0}>
      <Box
        p={2}
        sx={{
          borderRadius: 2,
          borderColor: 'lightgray',
          borderStyle: 'solid',
          borderWidth: '1px'
        }}
        component="fieldset">
        <legend>{firstTitle}</legend>
        {firstChild}
      </Box>
    </Grid>
    <Grid item xs={12} md={6} pl={0}>
      <Box
        p={2}
        sx={{
          borderRadius: 2,
          borderColor: 'lightgray',
          borderStyle: 'solid',
          borderWidth: '1px'
        }}
        component="fieldset">
        <legend>{secondTitle}</legend>
        {secondChild}
      </Box>
    </Grid>
  </Grid>
);
