import {useQuery} from 'react-query';
import axios from 'axios';
import {Laan} from '@app/domain/laan-beregner';
import {useRuntimeConfig} from '../../../domain/core/use-runtime-config';

// TODO: Fix in API
export type LaanTransformed = Laan & { bidrag: [number, number, number]; name: string };
// TODO: Move types to domain folder?
export interface LoanGroup {
  groupLabel: string;
  loans: Laan[];
}

const transformLaan = (laan: LaanTransformed): Laan => ({
  ...laan,
  bidragsSats: laan.bidrag,
  label: laan.name,
  rente: laan.rente / 100,
  afdragsfri: laan.afdragsfri ? 40 : 0
});

export const useGroupedLaanQuery = (queryKey: 'indfrielse' | 'optagelse', endpointUrl: string) => {
  endpointUrl = useRuntimeConfig().apiUrl;

  return useQuery<LoanGroup[]>(queryKey, async () => {
    const { data } = await axios.get(endpointUrl + '/kurser/' + queryKey);
    const laanOptions: LaanTransformed[] = data.laan.map(transformLaan);
    const sortedLaanOptions = laanOptions.sort(
      (a, b) => (b?.loebetid || getMaxRestLoebetid(b)) - (a?.loebetid || getMaxRestLoebetid(a))
    );

    const groupedLoans: LoanGroup[] = [];
    let currentGroup: number | null | undefined = null;
    sortedLaanOptions.forEach((loan: LaanTransformed) => {
      const loebetid = loan?.loebetid || getMaxRestLoebetid(loan);

      if (loebetid !== currentGroup) {
        currentGroup = loebetid;
        groupedLoans.push({
          groupLabel: `Løbetid ${loebetid} år`,
          loans: [loan]
        });
      } else {
        groupedLoans[groupedLoans.length - 1].loans.push(loan);
      }
    });

    return groupedLoans;
  });
};

function getMaxRestLoebetid(laan: Laan) {
  return Math.min(30, (laan.udloebsAar || 2053) - new Date().getFullYear());
}
