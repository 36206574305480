import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, useState } from 'react';
import { LoanGroup, useGroupedLaanQuery } from './data/use-laan-query';
import { toLagsLaan } from '../../domain/laan-beregner';
import { LaanVaelger } from './laan-vaelger';
import { Slider } from '../../components/slider/slider';
import { LaanDetaljer } from './laan-detaljer';
import { AnnuitetsTabel } from './annuitets-tabel';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import BeregnerInputContainer from '../../components/ui/beregner-input-container';
import { ResponsiveSideBySide } from '../../components/ui/responsive-side-by-side';
import DisclaimerPopover from '../disclaimer/disclaimer-popover';

export const ToLagsLaanView = ({ laanOptagelse }: { laanOptagelse: LoanGroup[] }) => {
  const [proportion, setProportion] = useState(50);
  const [laanebeloeb, setLaanebeloeb] = useState(2000000);
  const [boligPris, setBoligPris] = useState(2500000);
  const [laanType1, setLaanType1] = useState(laanOptagelse.flatMap((group) => group.loans)[0]);
  const [laanType2, setLaanType2] = useState(laanOptagelse.flatMap((group) => group.loans)[1]);

  const handleChange =
    (setter: (value: number) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      setter(parseFloat(event.target.value));
    };

  const swapLoans = () => {
    const laanType2Copy = laanType2;
    setLaanType2(laanType1);
    setLaanType1(laanType2Copy);

    setProportion(100 - proportion);
  };

  const belaaningsGrad = laanebeloeb / boligPris;
  const forHoejBelaaningsGrad = belaaningsGrad > 0.8;
  const forHoejBelaaningsGradText = forHoejBelaaningsGrad
    ? 'Lånet kan maximalt udgøre 80% af boligens pris.'
    : '';

  const laan1 = {
    rente: laanType1.rente,
    kurs: laanType1.kurs,
    bidragsSats: laanType1.bidragsSats,
    terminer: laanType1.maxTerminer || 120,
    afdragsfri: (laanType1.afdragsfri ? 10 : 0) * 4,
    fondCode: laanType1.fondCode
  };

  const laan2 = {
    rente: laanType2.rente,
    kurs: laanType2.kurs,
    bidragsSats: laanType2.bidragsSats,
    terminer: laanType2.maxTerminer || 120,
    afdragsfri: (laanType2.afdragsfri ? 10 : 0) * 4,
    fondCode: laanType2.fondCode
  };

  const terminer = 120; // TODO
  const laanBeregning = toLagsLaan(laan1, laan2, laanebeloeb, terminer, proportion, belaaningsGrad);

  return (
    <Stack flex="auto">
      <BeregnerInputContainer>
        <TextField
          error={forHoejBelaaningsGrad}
          label={'Beløb til udbetaling'}
          type="number"
          variant="outlined"
          inputProps={{ step: 50000 }}
          value={laanebeloeb}
          helperText={forHoejBelaaningsGradText}
          onChange={handleChange(setLaanebeloeb)}
          sx={{ backgroundColor: '#FAFAFA', maxWidth: '250px' }}
        />
        <TextField
          label={'Bolig pris'}
          type="number"
          variant="outlined"
          inputProps={{ step: 50000 }}
          value={boligPris}
          onChange={handleChange(setBoligPris)}
          sx={{ backgroundColor: '#FAFAFA', maxWidth: '250px' }}
        />

        {laanOptagelse.length !== 0 && (
          <>
            <ResponsiveSideBySide
              firstTitle="1. lån"
              firstChild={
                <LaanVaelger
                  laanOptions={laanOptagelse}
                  onSelectLoan={setLaanType1}
                  label={`Lån 0-${Math.round(belaaningsGrad * proportion)}%`}
                />
              }
              secondTitle="2. lån"
              secondChild={
                <LaanVaelger
                  laanOptions={laanOptagelse}
                  onSelectLoan={setLaanType2}
                  label={`Lån ${Math.round(belaaningsGrad * proportion)}-${Math.round(
                    belaaningsGrad * 100
                  )}%`}
                />
              }
            />
          </>
        )}
        <Stack direction="row" spacing={1}>
          <Tooltip title="Byt lån">
            <IconButton onClick={swapLoans}>
              <SwapHorizIcon />
            </IconButton>
          </Tooltip>
          <Box sx={{ flexGrow: 1 }}>
            <Slider
              label={'Deling'}
              onChange={setProportion}
              min={0}
              max={100}
              step={1}
              value={proportion}
            />
          </Box>
        </Stack>
        <DisclaimerPopover />
      </BeregnerInputContainer>

      {!forHoejBelaaningsGrad && laanOptagelse.length !== 0 && (
        <Stack spacing={2} p={2}>
          <Typography variant="h6" gutterBottom>
            Lån detaljer
          </Typography>
          <LaanDetaljer laanBeregning={laanBeregning} />
          <Typography variant="h6" gutterBottom>
            Annuitetstabel
          </Typography>
          <AnnuitetsTabel laanBeregning={laanBeregning} />
        </Stack>
      )}
    </Stack>
  );
};

export const ToLagsLaan = () => {
  const { data: laanOptagelse = [], isLoading: isLoading } = useGroupedLaanQuery(
    'optagelse',
    //'https://72ylpd13k2.execute-api.eu-north-1.amazonaws.com/v1/api/kurser'
    '/v1/api/kurser'
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {isLoading && <CircularProgress />}
      {laanOptagelse.length > 0 && <ToLagsLaanView laanOptagelse={laanOptagelse} />}
    </Box>
  );
};
