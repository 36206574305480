import { Laan, LaanBeregning } from '@app/domain/laan-beregner';
import { ParentSize } from '@visx/responsive';
import {
  AnimatedAxis,
  AnimatedLineSeries,
  buildChartTheme,
  Grid,
  Tooltip,
  XYChart
} from '@visx/xychart';

import { amber, grey, teal } from '@mui/material/colors';
import { all, create } from 'mathjs';
import { annuityConstant, estimateDiscountRate } from '../../domain/discount-rate-estimator';

const math = create(all, {});

// TODO rename interface
interface VisxDatum {
  dato: string;
  y: number;
  type?: string;
}

const accessors = {
  xAccessor: (d: VisxDatum) => d.dato,
  yAccessor: (d: VisxDatum) => Math.round(d.y)
};

const theme = buildChartTheme({
  backgroundColor: grey[100],
  colors: [teal[800], amber[800]],
  gridColor: grey[500],
  gridColorDark: grey[800],
  tickLength: 0
});

const extractDate = (i: number) => {
  const date = new Date();

  const nextDate = new Date(date.getFullYear(), date.getMonth() + i * 3, date.getDay());
  const month = nextDate.getMonth(); // JavaScript's getMonth() function starts at 0 for January
  let year = nextDate.getFullYear();

  let nextQuarterMonth;
  if (month < 3) {
    nextQuarterMonth = 3;
  } else if (month < 6) {
    nextQuarterMonth = 6;
  } else if (month < 9) {
    nextQuarterMonth = 9;
  } else {
    nextQuarterMonth = 0; // If we're in Q4, the next quarter is Q1 of the next year
    year += 1;
  }

  // Formatting date to DD-MM-YYYY
  const monthString = String(nextQuarterMonth + 1).padStart(2, '0'); // Months start from 0 in JS
  const yearString = String(year);

  return `01-${monthString}-${yearString}`;
};

export const OmlaegningCharts = (props: {
  nyLaanBeregning: LaanBeregning;
  nuvaerendeLaanBeregning: LaanBeregning;
  laanNuvaerende: Laan;
  kursNuvaernde?: number;
  maxLoebetid: number;
}) => {
  // TODO: extract method laanberegningToDatum
  const data = props.nyLaanBeregning.restgaeld.map((e, i): VisxDatum => {
    const formattedDate = extractDate(i);
    return {
      dato: formattedDate,
      y: e,
      type: 'Nyt lån'
    };
  });

  const dataNuvaerende = props.nuvaerendeLaanBeregning.restgaeld.map((e, i): VisxDatum => {
    const formattedDate = extractDate(i);
    return {
      dato: formattedDate,
      y: e,
      type: 'Nuværende lån'
    };
  });

  // Kursværdi nuværende estimeret
  const discountRateEstimate = estimateDiscountRate(
    props.laanNuvaerende.kurs || 100,
    props.laanNuvaerende.rente / 4,
    props.nuvaerendeLaanBeregning.restgaeld.length // TODO
  );

  const dataNuvaerendeKursVaerdi = props.nuvaerendeLaanBeregning.restgaeld.map(
    (restgaeld, i): VisxDatum => {
      const formattedDate = extractDate(i);

      const n = props.nuvaerendeLaanBeregning.restgaeld.length;
      const an_R = annuityConstant(props.laanNuvaerende.rente / 4, n - i);
      const an_r = annuityConstant((discountRateEstimate || props.laanNuvaerende.rente) / 4, n - i);
      const kursVaerdiRestgaeld = (restgaeld * an_r) / an_R;

      return {
        dato: formattedDate,
        y: kursVaerdiRestgaeld,
        type: 'Estimeret kursværdi nuværende lån'
      };
    }
  );

  return (
    <ParentSize>
      {({ width, height }) => {
        return (
          <XYChart
            height={height}
            width={width}
            theme={theme}
            margin={{ left: 80, right: 5, top: 10, bottom: 30 }}
            xScale={{ type: 'band' }}
            yScale={{ type: 'linear' }}>
            <Grid
              columns={true}
              rows={true}
              numTicks={5}
              stroke={grey[500]}
              lineStyle={{ opacity: 0.8 }}
            />
            <AnimatedLineSeries dataKey="Nyt lån" data={data} {...accessors} />
            <AnimatedLineSeries dataKey="Nuværende lån" data={dataNuvaerende} {...accessors} />
            <AnimatedLineSeries
              dataKey="Estimeret kursværdi nuværende lån"
              data={dataNuvaerendeKursVaerdi}
              stroke={'grey'}
              strokeDasharray="4 4"
              {...accessors}
            />
            <AnimatedAxis orientation="bottom" numTicks={5} stroke={grey[500]} />
            <AnimatedAxis orientation="left" numTicks={5} stroke={grey[500]} />
            <Tooltip<VisxDatum>
              snapTooltipToDatumX
              snapTooltipToDatumY
              showVerticalCrosshair
              showSeriesGlyphs
              renderTooltip={({ tooltipData, colorScale }) =>
                tooltipData?.nearestDatum &&
                colorScale && (
                  <div>
                    {tooltipData?.nearestDatum.datum.dato}
                    {Object.entries(tooltipData?.datumByKey || {}).map(([k, v]) => (
                      <>
                        <div style={{ color: colorScale(k) }}>
                          {k}
                          {', '}
                          {Math.round(v.datum.y).toLocaleString()}
                        </div>
                      </>
                    ))}
                  </div>
                )
              }
            />
          </XYChart>
        );
      }}
    </ParentSize>
  );
};

export const BreakEvenChart = (props: {
  nyLaanBeregning: LaanBeregning;
  nuvaerendeLaanBeregning: LaanBeregning;
  maxLoebetid: number;
}) => {
  const restGaeldDifference: number[] = math
    .subtract(
      math.matrix(props.nyLaanBeregning.restgaeld).resize([props.maxLoebetid * 4]),
      math.matrix(props.nuvaerendeLaanBeregning.restgaeld).resize([props.maxLoebetid * 4])
    )
    .toArray() as number[];

  // TODO: restgaeld kursværdi estimat
  // TODO: merydelse bruges til førtidig indfrielse - sparet ydelse kan også bruges som ydelse
  // - svarer til annuitetsopsparing i eks. kurs 80,

  const merYdelse: number[] = math
    .subtract(
      math.matrix(props.nyLaanBeregning.ydelseEfterSkat).resize([props.maxLoebetid * 4]),
      math.matrix(props.nuvaerendeLaanBeregning.ydelseEfterSkat).resize([props.maxLoebetid * 4])
    )
    .toArray() as number[];

  const akkumuleretMerYdelse = math.cumsum(merYdelse);
  const breakEven = math.add(akkumuleretMerYdelse as number[], restGaeldDifference);

  const zeroLineData = breakEven.map((e, i) => ({
    dato: extractDate(i),
    y: 0,
    type: '0'
  }));

  const breakEvenSeries = breakEven.map((e, i): VisxDatum => {
    const formattedDate = extractDate(i);

    return {
      dato: formattedDate,
      y: e,
      type: 'break even'
    };
  });

  return (
    <ParentSize>
      {({ width, height }) => {
        return (
          <XYChart
            height={height}
            width={width}
            theme={theme}
            margin={{ left: 80, right: 5, top: 10, bottom: 30 }}
            xScale={{ type: 'band' }}
            yScale={{ type: 'linear' }}>
            <Grid
              columns={true}
              rows={true}
              numTicks={5}
              stroke={grey[500]}
              lineStyle={{ opacity: 0.8 }}
            />
            <AnimatedLineSeries
              dataKey="Zero line"
              data={zeroLineData}
              {...accessors}
              stroke={'grey'}
              strokeDasharray="4 4"
            />
            <AnimatedLineSeries dataKey="Breakeven" data={breakEvenSeries} {...accessors} />
            <AnimatedAxis orientation="bottom" numTicks={5} stroke={grey[500]} />
            <AnimatedAxis orientation="left" numTicks={5} stroke={grey[500]} />
            <Tooltip<VisxDatum>
              snapTooltipToDatumX
              snapTooltipToDatumY
              showVerticalCrosshair
              showSeriesGlyphs
              renderTooltip={({ tooltipData, colorScale }) =>
                tooltipData?.nearestDatum &&
                colorScale && (
                  <div>
                    {tooltipData?.nearestDatum.datum.dato}
                    {Object.entries(tooltipData?.datumByKey || {}).map(
                      ([k, v]) =>
                        k != 'Zero line' && (
                          <>
                            <div style={{ color: colorScale(k) }}>
                              {k}
                              {', '}
                              {Math.round(v.datum.y).toLocaleString()}
                            </div>
                          </>
                        )
                    )}
                  </div>
                )
              }
            />
          </XYChart>
        );
      }}
    </ParentSize>
  );
};
