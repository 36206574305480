/* Copyright (C) 2022 Henrik Tobias Madsen
 */
import { all, create } from 'mathjs';

const math = create(all, {});

export const toLagsLaan = (
  laan1: Laan,
  laan2: Laan,
  beloeb: number,
  terminer: number,
  proportion: number,
  belaaningsGrad: number
): LaanBeregning => {
  const laan1beregning = beregnLaan(
    laan1,
    terminer,
    ((beloeb * proportion) / 100 / laan1.kurs) * 100,
    [0, proportion * belaaningsGrad]
  );
  const laan2beregning = beregnLaan(
    laan2,
    terminer,
    ((beloeb * (100 - proportion)) / 100 / laan2.kurs) * 100,
    [proportion * belaaningsGrad, belaaningsGrad * 100]
  );

  let bidragsSats = 0;
  if (proportion > 0) {
    bidragsSats += (laan1beregning.bidragsSats * proportion) / 100;
  }
  if (proportion < 100) {
    bidragsSats += (laan2beregning.bidragsSats * (100 - proportion)) / 100;
  }

  if (proportion === 0) {
    return laan2beregning;
  }
  if (proportion === 100) {
    return laan1beregning;
  }
  return {
    afdrag: math.add(laan1beregning.afdrag, laan2beregning.afdrag),
    bidrag: math.add(laan1beregning.bidrag, laan2beregning.bidrag),
    bidragsSats: bidragsSats,
    hovedstol: laan1beregning.hovedstol + laan2beregning.hovedstol,
    renter: math.add(laan1beregning.renter, laan2beregning.renter),
    restgaeld: math.add(laan1beregning.restgaeld, laan2beregning.restgaeld),
    skat: math.add(laan1beregning.skat, laan2beregning.skat),
    ydelse: math.add(laan1beregning.ydelse, laan2beregning.ydelse),
    ydelseEfterSkat: math.add(laan1beregning.ydelseEfterSkat, laan2beregning.ydelseEfterSkat),
    tkAfdrag: laan1beregning.tkAfdrag + laan2beregning.tkAfdrag,
    tkAfdragEfterAfdragsFri:
      laan1beregning.tkAfdragEfterAfdragsFri + laan2beregning.tkAfdragEfterAfdragsFri,
    tkRenteOgBidragTotal: laan1beregning.tkRenteOgBidragTotal + laan2beregning.tkRenteOgBidragTotal,
    tkSamletBeloeb: laan1beregning.tkSamletBeloeb + laan2beregning.tkSamletBeloeb,
    tkYdelse: laan1beregning.tkYdelse + laan2beregning.tkYdelse,
    tkYdelseEfterAfdragsFri:
      laan1beregning.tkYdelseEfterAfdragsFri + laan2beregning.tkYdelseEfterAfdragsFri,
    tkYdelseEfterSkat: laan1beregning.tkYdelseEfterSkat + laan2beregning.tkYdelseEfterSkat
  };
};

export interface LaanMedLoebetid extends Laan {
  /** Antal terminer (kvartaler) lån løber over */
  terminer: number;
}

export interface Laan {
  label?: string;
  rente: number;
  kurs: number;
  /** Bidrags sats for intervallerne 0-40, 40-60 og 60-80 */
  bidragsSats: [number, number, number];
  bidragsSatsAfdragsFriTillaeg?: [number, number, number];
  /** Maximalt antal terminer lån kan løbe over */
  maxTerminer?: number;
  /** Udloebsaar */
  udloebsAar?: number;
  /** Løbetid i år*/
  loebetid?: number;
  /** Antal terminer (kvartaler) */
  // terminer: number;
  /** Antal afdragsfri terminer (kvartaler) antages placeret indledningsvist */
  afdragsfri: number;
  fondCode: string;
}

export interface LaanBeregning {
  afdrag: number[];
  bidrag: number[];
  bidragsSats: number;
  hovedstol: number;
  renter: number[];
  restgaeld: number[];
  skat: number[];
  ydelse: number[];
  ydelseEfterSkat: number[];
  tkAfdrag: number;
  tkAfdragEfterAfdragsFri: number;
  tkRenteOgBidragTotal: number;
  tkSamletBeloeb: number;
  tkYdelse: number;
  tkYdelseEfterAfdragsFri: number;
  tkYdelseEfterSkat: number;
}

export const beregnLaan = (
  laan: Laan,
  terminer: number,
  hovedstol: number,
  interval: [number, number],
  skat = 0.256
): LaanBeregning => {
  const r = laan.rente / 4; // Rente per kvartal
  const bidragsSatsAfdragsFri = laan.bidragsSatsAfdragsFriTillaeg
    ? bidragsSatsBeregner(math.add(laan.bidragsSats, laan.bidragsSatsAfdragsFriTillaeg), interval)
    : 0;
  const bidragsSatsMedAfdrag = bidragsSatsBeregner(laan.bidragsSats, interval);

  const ydelseAfdragsfri = r * hovedstol;
  const ydelse = annuitetsBeregner(hovedstol, r, terminer - laan.afdragsfri);

  const afdragsfriTerminer = resterendeAfdragsfriTerminer(laan, terminer);

  const renterVek = new Array(terminer).fill(0);
  const bidragVek = new Array(terminer).fill(0);
  const restgaeldVek = new Array(terminer).fill(0);
  const ydelserVek = new Array(terminer).fill(0);
  const skatVek = new Array(terminer).fill(0);
  let restgaeld = hovedstol;

  for (let i = 0; i < terminer; i++) {
    renterVek[i] = r * restgaeld;
    if (i >= afdragsfriTerminer) {
      bidragVek[i] = (bidragsSatsMedAfdrag / 4) * restgaeld;
      restgaeld = restgaeld * (1 + r) - ydelse;
      ydelserVek[i] = ydelse;
    } else {
      bidragVek[i] = (bidragsSatsAfdragsFri / 4) * restgaeld;
      ydelserVek[i] = ydelseAfdragsfri;
    }

    restgaeldVek[i] = restgaeld;
    skatVek[i] = (renterVek[i] + bidragVek[i]) * skat;
  }

  return {
    hovedstol: hovedstol,
    ydelse: math.add(ydelserVek, bidragVek),
    ydelseEfterSkat: math.subtract(math.add(ydelserVek, bidragVek), skatVek),
    bidrag: bidragVek,
    renter: renterVek,
    afdrag: math.subtract(ydelserVek, renterVek),
    restgaeld: restgaeldVek,
    skat: skatVek,
    bidragsSats: laan.bidragsSatsAfdragsFriTillaeg ? bidragsSatsAfdragsFri : bidragsSatsMedAfdrag,
    tkYdelse: gnsMaanedFoersteAar(ydelserVek) + gnsMaanedFoersteAar(bidragVek),
    tkYdelseEfterSkat:
      gnsMaanedFoersteAar(ydelserVek) +
      gnsMaanedFoersteAar(bidragVek) -
      gnsMaanedFoersteAar(skatVek),
    tkAfdrag: gnsMaanedFoersteAar(ydelserVek) - gnsMaanedFoersteAar(renterVek),
    tkYdelseEfterAfdragsFri:
      gnsMaanedFoersteAarEfterAfdragsFri(ydelserVek, laan.afdragsfri) +
      gnsMaanedFoersteAarEfterAfdragsFri(bidragVek, laan.afdragsfri),
    tkAfdragEfterAfdragsFri:
      gnsMaanedFoersteAarEfterAfdragsFri(ydelserVek, laan.afdragsfri) -
      gnsMaanedFoersteAarEfterAfdragsFri(renterVek, laan.afdragsfri),
    tkRenteOgBidragTotal: math.sum(renterVek) + math.sum(bidragVek),
    tkSamletBeloeb: math.sum(ydelserVek) + math.sum(bidragVek)
  };
};

function resterendeAfdragsfriTerminer(laan: Laan, resterendeTerminer: number): number {
  if (laan.afdragsfri == 0) {
    return 0;
  }
  // Assume 30 year loan
  const terminerPassed = 4 * 30 - resterendeTerminer;
  return Math.max(laan.afdragsfri - terminerPassed, 0);
}

function gnsMaanedFoersteAar(arr: number[]) {
  return arr.slice(0, 4).reduce((acc, a) => acc + a, 0) / 12;
}

function gnsMaanedFoersteAarEfterAfdragsFri(arr: number[], afdragsfriOffset: number) {
  return arr.slice(afdragsfriOffset, 4 + afdragsfriOffset).reduce((acc, a) => acc + a, 0) / 12;
}

function annuitetsBeregner(hovedstol: number, rente: number, terminer: number) {
  if (rente == 0) {
    return hovedstol / terminer;
  }
  return (rente / (Math.pow(1 + rente, terminer) - 1)) * hovedstol * Math.pow(1 + rente, terminer);
}

export const bidragsSatsBeregner = (
  bidragPerInterval: [number, number, number],
  interval: [number, number] = [0, 80]
) => {
  const overlap_0_40 = overlapStoerrelse([0, 40], interval);
  const overlap_40_60 = overlapStoerrelse([40, 60], interval);
  const overlap_60_80 = overlapStoerrelse([60, 80], interval);

  const weightedSum =
    bidragPerInterval[0] * overlap_0_40 +
    bidragPerInterval[1] * overlap_40_60 +
    bidragPerInterval[2] * overlap_60_80;

  return weightedSum / (interval[1] - interval[0]);
};

const overlapStoerrelse = (interval1: [number, number], interval2: [number, number]) => {
  const overlap = Math.min(interval1[1], interval2[1]) - Math.max(interval1[0], interval2[0]);
  return Math.max(overlap, 0);
};
