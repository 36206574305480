import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { Laan } from '../../domain/laan-beregner';
import { LoanGroup } from './data/use-laan-query';
import React, { useState } from 'react';

export interface LaanVaelgerProps {
  label?: string;
  laanOptions: LoanGroup[];
  onSelectLoan: (laan: Laan) => void;
}

export const LaanVaelger = ({ label, laanOptions, onSelectLoan }: LaanVaelgerProps) => {
  const [selectedLoan, setSelectedLoan] = useState<Laan>(
    laanOptions.flatMap((group) => group.loans)[0]
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedFondCode = event.target.value;
    const selectedLoan =
      laanOptions
        .flatMap((group) => group.loans)
        .find((laan) => laan.fondCode == selectedFondCode) ||
      laanOptions.flatMap((group) => group.loans)[0];
    setSelectedLoan(selectedLoan);
    onSelectLoan(selectedLoan); // Pass the selected loan to the parent component
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          labelId="select-label-laan1"
          label={label}
          value={selectedLoan ? selectedLoan.fondCode : ''}
          sx={{ backgroundColor: '#FAFAFA' }}
          onChange={handleChange}>
          {laanOptions.map((group) => {
            const arr1 = <ListSubheader>{group.groupLabel}</ListSubheader>;
            const arr2 = group.loans.map((loan) => (
              <MenuItem value={loan.fondCode} key={loan.fondCode}>
                {loan.label}
              </MenuItem>
            ));
            return [arr1].concat(arr2);
          })}
        </Select>
      </FormControl>
      <HovedTal laan={selectedLoan} />
    </>
  );
};

export const HovedTal = ({ laan }: { laan: Laan }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{ pl: 0.5 }} component="th" scope="row">
            Rente
          </TableCell>
          <TableCell align="right">{laan.rente * 100} %</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ pl: 0.5 }} component="th" scope="row">
            Kurs
          </TableCell>
          <TableCell align="right">{laan.kurs}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ pl: 0.5 }} component="th" scope="row">
            Maksimal løbetid
          </TableCell>
          <TableCell align="right">{laan.loebetid ? Math.floor(laan?.loebetid) : '-'} år</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ pl: 0.5, borderBottom: 'none' }} component="th" scope="row">
            Afdragsfri
          </TableCell>
          <TableCell sx={{ borderBottom: 'none' }} align="right">
            {laan.afdragsfri ? '10' : '0'} år
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
