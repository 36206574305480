import React from 'react';
import { Box, Stack } from '@mui/material';

const BeregnerInputContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      p={2}
      sx={{
        borderRadius: 4,
        background: 'whitesmoke',
        borderColor: 'lightgray',
        borderStyle: 'solid',
        borderWidth: '1px'
      }}>
      <Stack spacing={2}>{children}</Stack>
    </Box>
  );
};

export default BeregnerInputContainer;
