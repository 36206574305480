import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

const AccountMenu = ({ openLogin }: { openLogin: () => void }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit">
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}>
        {user && (
          <ListItem>
            <ListItemText>{user?.signInDetails?.loginId}</ListItemText>
          </ListItem>
        )}
        <MenuItem component={Link} to="/profil">
          PROFIL
        </MenuItem>
        {user && <MenuItem onClick={signOut}>LOG UD</MenuItem>}
        {!user && (
          <MenuItem
            onClick={() => {
              openLogin();
              handleClose();
            }}>
            LOGIN
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default AccountMenu;
