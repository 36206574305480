import { BisectionMethod } from './bisection';

export const annuityConstant = (r: number, n: number): number => {
  return (1 - Math.pow(1 + r, -n)) / r;
};

export const estimateDiscountRate = (price: number, r: number, n: number): number | null => {
  const an_r: number = annuityConstant(r, n);

  const f1 = (rhat: number): number => {
    return (price / 100) * an_r - annuityConstant(rhat, n);
  };

  const root: number | null = BisectionMethod.findRoot(f1, 0.0001, 0.2);

  return root === null ? null : root * 4;
};
