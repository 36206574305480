import React from 'react';

import TextField from '@mui/material/TextField';
import { Slider as MuiSlider, Stack } from '@mui/material';

export interface SliderProps {
  value: number;
  min: number;
  max: number;
  label: string;
  step?: number;
  onChange: (newValue: number) => void;
}

export const Slider = ({ value, min, max, step = 1, label, onChange }: SliderProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(parseFloat(event.target.value));
  };

  return (
    <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
      <TextField
        label={label}
        value={value}
        onChange={handleChange}
        margin="normal"
        type="number"
      />
      <MuiSlider
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(event: Event, value: number | number[]) => {
          onChange(value as number);
        }}
        sx={{ flexGrow: 10 }}
      />
    </Stack>
  );
};
