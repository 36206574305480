import { LaanVaelger } from './laan-vaelger';
import { LaanDetaljer } from './laan-detaljer';
import { AnnuitetsTabel } from './annuitets-tabel';
import { ChangeEvent, useState } from 'react';
import { beregnLaan } from '../../domain/laan-beregner';
import { Box, CircularProgress, Divider, Stack, TextField } from '@mui/material';
import { LoanGroup, useGroupedLaanQuery } from './data/use-laan-query';
import BeregnerInputContainer from '../../components/ui/beregner-input-container';
import DisclaimerPopover from '../disclaimer/disclaimer-popover';

export const StandardLaanView = ({ laanOptagelse }: { laanOptagelse: LoanGroup[] }) => {
  const [boligPris, setBoligPris] = useState(2500000);
  const [laanebeloeb, setLaanebeloeb] = useState(2000000);
  const [valgtLaan, setValgtLaan] = useState(laanOptagelse.flatMap((group) => group.loans)[0]);
  // TODO: Vælg løbetid

  const handleChange =
    (setter: (value: number) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      setter(parseFloat(event.target.value));
    };

  const belaaningsGrad = laanebeloeb / boligPris;
  const forHoejBelaaningsGrad = belaaningsGrad > 0.8;
  const forHoejBelaaningsGradText = forHoejBelaaningsGrad
    ? 'Lånet kan maximalt udgøre 80% af boligens pris.'
    : '';

  const hovedstol = laanebeloeb / (valgtLaan.kurs / 100);
  const laanBeregning = beregnLaan(valgtLaan, 120, hovedstol, [0, 80]);

  return (
    <Stack spacing={2} flex="auto">
      <BeregnerInputContainer>
        <TextField
          error={forHoejBelaaningsGrad}
          label={'Beløb til udbetaling'}
          type="number"
          variant="outlined"
          inputProps={{ step: 50000 }}
          value={laanebeloeb}
          helperText={forHoejBelaaningsGradText}
          onChange={handleChange(setLaanebeloeb)}
          sx={{ backgroundColor: '#FAFAFA', maxWidth: '250px' }}
        />
        <TextField
          label={'Bolig pris'}
          type="number"
          variant="outlined"
          inputProps={{ step: 50000 }}
          value={boligPris}
          onChange={handleChange(setBoligPris)}
          sx={{ backgroundColor: '#FAFAFA', maxWidth: '250px' }}
        />
        <Divider />
        <LaanVaelger label="Vælg lån" laanOptions={laanOptagelse} onSelectLoan={setValgtLaan} />
        <DisclaimerPopover />
      </BeregnerInputContainer>
      <LaanDetaljer laanBeregning={laanBeregning} />
      <AnnuitetsTabel laanBeregning={laanBeregning} />
    </Stack>
  );
};

export const StandardLaan = () => {
  const { data: laanOptagelse = [], isLoading } = useGroupedLaanQuery(
    'optagelse',
    //'https://72ylpd13k2.execute-api.eu-north-1.amazonaws.com/v1/api/kurser'
    '/v1/api/kurser'
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {isLoading && <CircularProgress />}
      {laanOptagelse.length > 0 && <StandardLaanView laanOptagelse={laanOptagelse} />}
    </Box>
  );
};
